import { useConfig } from "@agportal/config";
import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { createSecurityGuard as createSecurityGuardBase } from "@dmvs/composables/security";

const config = useConfig();
const logger = useLogger("security");
const gadClient = createRestClient({ baseURL: config.endpoints.gad }, { secured: false });

async function loadUserDetails(context) {
    return {
        uozi: await isAziUser(context),
    };
}

async function isAziUser(context) {
    try {
        const { data } = await gadClient.post(
            "/azi/subjekty/moje",
            {
                from: 0,
                size: 0,
                getTotal: true,
            },
            {
                headers: {
                    Authorization: `Bearer ${context.accessToken}`,
                },
            }
        );

        const azi = data.total > 0;
        logger.debug("Ověřování AZI skončilo s výsledkem {0}.", azi);

        return azi;
    } catch (error) {
        logger.error("Došlo k chybě při ověřování AZI.", error);
    }

    return false;
}

export function createSecurityGuard() {
    return createSecurityGuardBase({ loadUserDetails });
}
