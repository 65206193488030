import { createRouter } from "@agportal/router";
import { createGroupGuard } from "@dmvs/composables/routing";
import { useSecurityContext } from "@dmvs/composables/security";
import { routes as common } from "@dmvs/router";
import { concat } from "lodash-es";

import { createSecurityGuard } from "@/security";

const router = createRouter({
    routes: concat(
        [],
        [
            {
                path: "/",
                name: "uvod",
                redirect: {
                    path: "/portal",
                },
            },
            {
                path: "/portal",
                name: "portal",
                component: () => import("@/views/Uvod"),
                meta: {
                    breadcrumbs: false,
                },
            },
            {
                path: "/portal/registrace",
                name: "registrace",
                component: () => import("@/views/Registrace"),
                meta: {
                    label: "Registrace",
                    breadcrumbs: true,
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-uzivatele",
                name: "povereni-uzivatele",
                component: () => import("@/views/PovereniUzivatele"),
                meta: {
                    label: "Pověření uživatele",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-editora-dti",
                name: "povereni-editora-dti",
                component: () => import("@/views/PovereniEditoraDti"),
                meta: {
                    label: "Pověření editora",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-editora-zps",
                name: "povereni-editora-zps",
                component: () => import("@/views/PovereniEditoraZps"),
                meta: {
                    label: "Pověření editora ZPS",
                    secured: true,
                },
            },
            {
                path: "/portal/zmena-uzemi-editace-zps",
                name: "zmena-uzemi-editace-zps",
                component: () => import("@/views/ZmenaUzemiEditaceZps"),
                meta: {
                    label: "Změna území editace ZPS",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-zakladatele",
                name: "povereni-zakladatele",
                component: () => import("@/views/PrirazeniZakladatele"),
                meta: {
                    label: "Pověření zakladatele",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-vyjadrovatele",
                name: "povereni-vyjadrovatele",
                component: () => import("@/views/PovereniVyjadrovatele"),
                meta: {
                    label: "Pověření vyjadřovatele",
                    secured: true,
                },
            },
            {
                path: "/portal/sprava-subjektu",
                name: "sprava-subjektu",
                component: () => import("@/views/SpravaSubjektu"),
                redirect: {
                    name: "informace-o-subjektu",
                },
                meta: {
                    label: "Správa subjektu",
                    group: "sprava-subjektu",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "informace",
                        name: "informace-o-subjektu",
                        component: () => import("@/components/composition/InformaceSubjektu"),
                        meta: {
                            label: "Informace",
                        },
                    },
                    {
                        path: "notifikace-subjektu",
                        name: "notifikace-subjektu",
                        component: () => import("@/components/composition/NastaveniNotifikaciSubjektu"),
                        meta: {
                            label: "Notifikace",
                        },
                    },
                    {
                        path: "registry",
                        component: () => import("@/components/composition/RegistrySubjektu"),
                        meta: {
                            label: "Registry",
                        },
                    },
                    {
                        path: "dtm",
                        component: () => import("@/components/composition/InformaceIsDtm"),
                        meta: {
                            label: "DTM",
                        },
                    },
                    {
                        path: "zps",
                        component: () => import("@/components/composition/InformaceEditoraZps"),
                        meta: {
                            label: "Editor ZPS",
                        },
                    },
                    {
                        path: "zakladatele",
                        component: () => import("@/components/composition/ZakladateleSubjektu"),
                        meta: {
                            label: "Zakladatelé",
                        },
                    },
                    {
                        path: "povereni",
                        component: () => import("@/components/composition/UzivateleSubjektu"),
                        meta: {
                            label: "Pověření",
                        },
                    },
                ],
            },
            {
                path: "/portal/casti-dti",
                name: "casti-dti",
                component: () => import("@/views/CastiDti"),
                meta: {
                    label: "Části DTI",
                    group: "casti-dti",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: ":castDtiId",
                        name: "detail-casti-dti",
                        meta: {
                            label: "#{castDti}",
                        },
                        component: () => import("@/views/DetailCastiDti"),
                        redirect: {
                            name: "informace-casti-dti",
                        },
                        children: [
                            {
                                path: "informace",
                                name: "informace-casti-dti",
                                component: () => import("@/components/composition/InformaceCastiDti"),
                                meta: {
                                    label: "Informace",
                                },
                            },
                            {
                                path: "vyjadrovatel",
                                component: () => import("@/components/composition/Vyjadrovatel"),
                                meta: {
                                    label: "Vyjadřovatel",
                                },
                            },
                            {
                                path: "kontaktni-udaje",
                                component: () => import("@/components/composition/KontaktniUdajeCastiDti"),
                                meta: {
                                    label: "Kontaktní údaje",
                                    tooltip: "Kontaktní údaje pro podání žádosti o stanovisko",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/rozsahy-editace-dti",
                name: "rozsahy-editace-dti",
                component: () => import("@/views/RozsahyEditaceDti"),
                meta: {
                    label: "Rozsahy editace DTI",
                    group: "rozsahy-editace-dti",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: ":rozsahDtiId",
                        name: "detail-rozsahu-dti",
                        meta: {
                            label: "#{rozsahDti}",
                        },
                        component: () => import("@/views/DetailRozsahuEditaceDti"),
                        redirect: {
                            name: "informace-rozsahu-dti",
                        },
                        children: [
                            {
                                path: "informace",
                                name: "informace-rozsahu-dti",
                                component: () => import("@/components/composition/InformaceRozsahuDti"),
                                meta: {
                                    label: "Informace",
                                },
                            },
                            {
                                path: "editori",
                                component: () => import("@/components/composition/Editori"),
                                meta: {
                                    label: "Editoři",
                                },
                            },
                        ],
                    },
                ],
            },
            // GAD routes for users registered in AZI register
            {
                path: "/portal/gad",
                name: "gad",
                component: () => import("@/views/RozcestnikGad"),
                meta: {
                    label: "Geodetická aktualizační dokumentace",
                    breadcrumbs: true,
                    secured: true,
                },
                beforeEnter: (to, from, next) => {
                    const { details } = useSecurityContext();

                    if (!details.value.uozi) {
                        next({ name: "stav-zpracovani-gad" });
                    } else {
                        next();
                    }
                },
                children: [
                    {
                        path: "stav-zpracovani",
                        name: "gad-stav-zpracovani",
                        component: () => import("@/views/StavPodaniGad"),
                        meta: {
                            label: "Stav zpracování",
                        },
                    },
                    {
                        path: "kontrola-dat",
                        name: "gad-kontrola-dat",
                        component: () => import("@/views/KontrolniZaslaniGad"),
                        meta: {
                            label: "Kontrola dat",
                        },
                    },
                    {
                        path: "aktualizace-dat",
                        name: "gad-aktualizace-dat",
                        component: () => import("@/views/ZaslaniZmenyGad"),
                        meta: {
                            label: "Aktualizace dat",
                        },
                    },
                    {
                        path: "podani",
                        name: "podani-gad",
                        component: () => import("@/views/PodaniGad"),
                        meta: {
                            label: "Přehled podání",
                            group: "podani-gad",
                        },
                        children: [
                            {
                                path: ":idPodani",
                                name: "detail-podani-gad",
                                meta: {
                                    label: "#{idPodani}",
                                },
                                component: () => import("@/views/DetailPodaniGad"),
                            },
                        ],
                    },
                ],
            },
            // GAD route for users not registered in AZI register
            {
                path: "/portal/stav-zpracovani-gad",
                name: "stav-zpracovani-gad",
                component: () => import("@/views/StavPodaniGad"),
                meta: {
                    label: "Stav zpracování",
                    secured: true,
                },
            },

            {
                path: "/portal/vydej-dat",
                name: "vydej-dat",
                component: () => import("@/views/RozcestnikVydejDat"),
                meta: {
                    label: "Výdej dat",
                    group: "vydej-dat",
                    breadcrumbs: true,
                },
                children: [
                    {
                        path: "datove-sady-zps",
                        name: "datove-sady-zps",
                        component: () => import("@/views/OpenData"),
                        meta: {
                            label: "Veřejné datové sady ZPS",
                        },
                    },
                    {
                        path: "predpripravene-datove-sady",
                        name: "predpripravene-datove-sady",
                        component: () => import("@/views/VydejPreddefinovanychDatovychSad"),
                        meta: {
                            label: "Výdej předpřipravených datových sad",
                            secured: true,
                        },
                    },
                    {
                        path: "data-v-definovanem-uzemi",
                        name: "data-v-definovanem-uzemi",
                        component: () => import("@/views/VyhotoveniDatovychSad"),
                        meta: {
                            label: "Výdej dat v definovaném území",
                            secured: true,
                        },
                    },
                    {
                        path: "zadosti",
                        name: "zadosti",
                        component: () => import("@/views/PrehledZadostiVydejeDat"),
                        meta: {
                            label: "Přehled žádostí",
                            group: "zadosti-o-vydej-dat",
                            secured: true,
                        },
                        children: [
                            {
                                path: ":typZadosti/:zadostId",
                                name: "detail-zadosti",
                                meta: {
                                    label: "#{zadostId}",
                                },
                                component: () => import("@/views/DetailZadostiVydejeDat"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/uzemi-spravcu-dti",
                name: "uzemi-spravcu-dti",
                component: () => import("@/views/ZjisteniUzemiSpravcuDti"),
                meta: {
                    label: "Zjištění území správců dopravní a technické infrastruktury",
                    secured: true,
                },
            },
            {
                path: "/portal/jvf",
                name: "jvf",
                component: () => import("@/views/RozcestnikJvf"),
                meta: {
                    label: "JVF DTM",
                    group: "jvf",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "verze-jvf",
                        name: "verze-jvf",
                        component: () => import("@/views/Jvf"),
                        meta: {
                            label: "Verze JVF",
                        },
                    },
                    {
                        path: "validace-jvf",
                        name: "validace-jvf",
                        component: () => import("@/views/ValidaceJvf"),
                        meta: {
                            label: "Validace JVF souboru",
                        },
                    },
                    {
                        path: "pozadavky-validace-jvf",
                        name: "pozadavky-validace-jvf",
                        component: () => import("@/views/PrehledPozadavkuNaValidaciJvf"),
                        meta: {
                            label: "Požadavky na validaci JVF",
                            group: "pozadavky-validace-jvf",
                        },
                        children: [
                            {
                                path: ":subjektId/:pozadavekId",
                                name: "detail-pozadavku-validace",
                                meta: {
                                    label: "#{typPozadavku}",
                                },
                                component: () => import("@/views/DetailPozadavkuValidaceDat"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/prehledy-ciselniky",
                name: "prehledy-ciselniky",
                component: () => import("@/views/RozcestnikCiselniky"),
                meta: {
                    label: "Přehledy a číselníky",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "ciselniky",
                        name: "ciselniky",
                        component: () => import("@/views/Ciselniky"),
                        meta: {
                            label: "Číselníky",
                        },
                    },
                    {
                        path: "certifikaty",
                        name: "certifikaty",
                        component: () => import("@/views/Certifikaty"),
                        meta: {
                            label: "Certifikáty",
                        },
                    },
                    {
                        path: "azi",
                        name: "azi",
                        component: () => import("@/views/Azi"),
                        meta: {
                            label: "AZI",
                        },
                    },
                ],
            },
            {
                path: "/portal/informace-o-systemu",
                name: "informace-o-systemu",
                component: () => import("@/views/InformaceOSystemu"),
                meta: {
                    label: "Informace o systému",
                    group: "informace-o-systemu",
                    breadcrumbs: true,
                },
                children: [
                    {
                        path: ":uuid",
                        name: "detail-informace-o-systemu",
                        component: () => import("@/views/DetailInformaceOSystemu"),
                        meta: {
                            label: "#{titulek}",
                            breadcrumbs: true,
                        },
                    },
                ],
            },
            {
                path: "/portal/stav-is-dtm-kraju",
                name: "stav-is-dtm-kraju",
                component: () => import("@/views/StavIsDtmKraju"),
                meta: {
                    label: "Stav IS DTM krajů",
                    breadcrumbs: true,
                },
            },
            {
                path: "/portal/notifikace",
                name: "notifikace",
                component: () => import("@/views/Notifikace"),
                meta: {
                    label: "Notifikace",
                    breadcrumbs: true,
                    group: "notifikace",
                },
            },
            {
                path: "/mapovy-portal",
                name: "mapovy-portal",
                component: () => import("@/views/RozcestnikMapovyPortal"),
                meta: {
                    label: "Mapový portál",
                    breadcrumbs: false,
                },
                children: [
                    {
                        path: "mapa",
                        name: "detail-mapovy-portal",
                        meta: {
                            label: "Mapa",
                            fullwidth: true,
                        },
                        component: () => import("@/views/MapovyPortal"),
                    },
                ],
            },
            {
                path: "/informace-o-dmvs",
                name: "informace-o-dmvs",
                component: () => import("@/views/InformaceODmvs"),
                meta: {
                    label: "Informace o DMVS",
                    breadcrumbs: false,
                },
            },
            {
                path: "/prohlaseni-o-pristupnosti",
                name: "prohlaseni-o-pristupnosti",
                component: () => import("@/views/ProhlaseniOPristupnosti"),
                meta: {
                    label: "Prohlášení o přístupnosti",
                    breadcrumbs: true,
                },
            },
            {
                path: "/rss",
                name: "rss",
                component: () => import("@/views/SeznamRssKanalu"),
                meta: {
                    label: "Seznam RSS kanálů",
                    breadcrumbs: true,
                },
            },
        ],
        common
    ),
});

router.beforeEach(createSecurityGuard());
router.beforeEach(createGroupGuard());

export default router;
