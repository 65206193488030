import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";
import { isEmpty, isNil } from "lodash-es";

export default class GadService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async ctiStavPodani(idPodani) {
        const { data } = await this.rest.get(`/podani/by-uuid/${idPodani}`);

        this.logger.debug("GadService.ctiStavPodani for id {0} returns {1}", idPodani, data);

        return data;
    }

    async stahniReport(idPodani, isDtmk) {
        const { data } = await this.rest.get(`/podani/by-uuid/${idPodani}/by-isdtmk/${isDtmk}/report`, {
            responseType: "blob",
        });

        this.logger.debug(
            "GadService.stahniReport for id {0} and isDtmk {1} returns {2}",
            idPodani,
            isDtmk,
            data
        );

        return new Blob([data], {
            type: data.type,
        });
    }

    async stahniVystupniSoubor(idPodani, isDtmk) {
        const { data } = await this.rest.get(`/podani/by-uuid/${idPodani}/by-isdtmk/${isDtmk}/vystupni-soubor`, {
            responseType: "blob",
        });

        this.logger.debug(
            "GadService.stahniVystupniSoubor for id {0} and isDtmk {1} returns {2}",
            idPodani,
            isDtmk,
            data
        );

        return new Blob([data], {
            type: data.type,
        });
    }

    async prijem(gad) {
        if (!isEmpty(gad)) {
            const { data } = await this.rest.post("/azi/pozadavek-na-zapracovani/prijem", gad);

            this.logger.debug("GadService.prijem for gad {0} returns {1}", gad, data);

            return data;
        }
    }

    async vylistujPozadavkyNaZapracovaniGad(idUozi, query) {
        if (!isNil(idUozi) && !isEmpty(query)) {
            const { data } = await this.rest.post(`/azi/pozadavek-na-zapracovani/by-azi/${idUozi}/query`, query);

            this.logger.debug(
                "GadService.vylistujPozadavkyNaZapracovaniGad for azi {0} and query {1} returns {2}",
                idUozi,
                query,
                data
            );

            return data;
        }

        return null;
    }

    async ctiPozadavekNaZapracovaniGad(idUozi, idPozadavku) {
        if (!isNil(idUozi) && !isNil(idPozadavku)) {
            const { data } = await this.rest.get(`/azi/pozadavek-na-zapracovani/by-azi/${idUozi}/by-id/${idPozadavku}`);

            this.logger.debug(
                "GadService.ctiPozadavekNaZapracovaniGad for azi {0} and idPozadavku {1} returns {2}",
                idUozi,
                idPozadavku,
                data
            );

            return data;
        }

        return null;
    }

    async vylistujMojeSubjektyGad(query) {
        if (!isEmpty(query)) {
            const { data } = await this.rest.post("azi/subjekty/moje", query);

            this.logger.debug(
                "GadService.vylistujMojeSubjektyGad for query {0} returns {1}",
                query,
                data
            );

            return data;
        }

        return null;
    }

    async nactiDetailSubjektuGad(idSubjektu) {
        if (!isNil(idSubjektu)) {
            const { data } = await this.rest.get(`azi/subjekty/moje/by-id/${idSubjektu}`);

            this.logger.debug(
                "GadService.nactiDetailSubjektuGad for idSubjektu {0} returns {1}",
                idSubjektu,
                data
            );

            return data;
        }

        return null;
    }
}
